<template>
  <div class="text-center">
    <div v-if="isLoading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-if="isLoading == false">
      <p v-if="isPharmacyOwner === false">
        Only pharmacy owners are allowed to update subscription plan
      </p>
      <v-btn
        v-if="isPharmacyOwner && isSubscribed === true"
        color="primary"
        @click="goto(url)"
      >
        Manage your PHOX subscription
      </v-btn>
      <pricing-table v-if="isPharmacyOwner && isSubscribed === false"></pricing-table>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { ref } from '@vue/composition-api'
import PricingTable from '../components/Stripe/PricingTable.vue'

export default {
  name: 'PlanPicker',
  components: {
    PricingTable,
  },
  setup() {
    return {
      url: ref(''),
      isLoading: ref(true),
      isSubscribed: ref(false),

      // assumption that its always the pharmacy owner
      isPharmacyOwner: ref(true),
    }
  },
  async mounted() {
    await this.getManageSubscriptionUrl()
      .then(url => {
        if (url) {
          this.url = url
          this.isSubscribed = true
        }
      })
      .catch(({ response }) => {
        switch (response.data.statusCode) {
          case 400:
            this.isPharmacyOwner = false
            break
          case 404:
            this.isSubscribed = false
            break
          default:

            // FIXME: redirect to login page
        }
      }).finally(() => {
        this.isLoading = false
      })
  },
  methods: {
    goto(url) {
      window.location.replace(url)
    },
    async getManageSubscriptionUrl() {
      // const accessToken = await this.$auth.getTokenSilently()
      //
      // return axios
      //   .post(
      //     // FIXME: this needs to be fed  by env. url
      //     // `https://api.dev.pharmaguide.ca/customer-portal-session?return_url=${window.location.href}`,
      //     `http://localhost:3000/customer-portal-session?return_url=${window.location.href}`,
      //     {},
      //     {
      //       headers: {
      //         Authorization: `Bearer ${accessToken}`,
      //         'Content-Type': 'multipart/form-data',
      //       },
      //     },
      //   )
      //   .then(resp => resp.data)
    },
  },
}
</script>
