<template>
  <stripe-pricing-table
    :pricing-table-id="settings.pricingTableId"
    :publishable-key="settings.publishableKey"
    :client-reference-id="settings.hash"
  >
  </stripe-pricing-table>
</template>

<script>
import Vue from 'vue'
import { STRIPE } from '@/config'

// stops vue from throwing errors when using stripe-pricing-table
Vue.config.ignoredElements = ['stripe-pricing-table']

export default {
  name: 'PricingTable',
  data() {
    return {
      settings: {
        // TEST values, prod keys comes from env. variables
        // eslint-disable-next-line no-undef
        pricingTableId: STRIPE.PRICING_TABLE_ID,
        // eslint-disable-next-line no-undef
        publishableKey: STRIPE.PUBLIC_KEY,
        email: this.$auth.user.email,
        hash: this.$auth.user['https://api.pharmaguide.ca/claims/app_metadata'].hmac,
      },
    }
  },
  mounted() {
    this.initPricingTableScript()
  },
  methods: {
    initPricingTableScript() {
      const s = document.createElement('script')
      s.src = 'https://js.stripe.com/v3/pricing-table.js'
      s.async = true
      s.type = 'text/javascript'
      const x = document.getElementsByTagName('script')[0]
      x.parentNode.insertBefore(s, x)
    },
  },
}
</script>
